<template>
    <div class="d-flex bg-white m-1 px-3 rounded-pill align-items-center" style="height: 48px">
        <nav
            style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
            aria-label="breadcrumb">
            <ol class="breadcrumb m-auto">
                <li v-for="(item, index) in path" v-bind:key="index"
                    class="breadcrumb-item"
                    :class="{active: index === path.length - 1}"
                    v-bind="{ 'aria-current': index === path.length - 1 ? 'page' : null }">
                    <a href="#" class="breadcrumb-title" @click.prevent="goPath(item)">
                        <folder-icon class="me-2" :width="32" :height="32"/>
                        <span>{{ item.DTM_GROUP_NAME }}</span>
                    </a>
                </li>
            </ol>
        </nav>
    </div>
</template>

<script>
import {defineAsyncComponent} from 'vue'
import {router} from "@/routes";
const FolderIcon = defineAsyncComponent(() => import ("@/components/atoms/FolderIcon"));

export default {
    name: "Breadcrumb",
    components: {FolderIcon},
    props: {
        path: Array,
    },
    methods: {
        goPath(data) {
            const dtmGroupNo = data.DTM_GROUP_NO;
            let params = {};

            if (dtmGroupNo) {
                params = {
                    type: this.$store.getters['drive/driverKind'],
                    parentGroupNo: dtmGroupNo
                }
            }

            router.push({name: "cloud", query: params});
        }
    }
}
</script>

<style scoped>
.breadcrumb-item {
    display: flex;
    justify-content: center;
    align-items: center;
}
/* span {
    display: inline-block;
    margin-top: 10px;
} */
.breadcrumb-title {
    color: #44403F;
    font-size: 1.5rem;
}
</style>