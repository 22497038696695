<template>
    <div class="btn-group dropup">
        <button type="button" class="btn btn-secondary dropdown-toggle rounded-circle" ref="dropdown-menu-button"
                @click="toggleDropdown"
                aria-label="Dropdown menu" data-bs-toggle="dropdown" aria-expanded="false">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="16.2002" y1="4.37112e-08" x2="16.2002" y2="32" stroke="white" stroke-width="2"/>
                <line y1="15.8008" x2="32" y2="15.8008" stroke="white" stroke-width="2"/>
                </svg>
        </button>
        <ul class="dropdown-menu p-0 rounded-5">
            <li v-if="buttonRole.showCreateNewCanvasButton"><router-link class="dropdown-item text-white text-center" to="canvas">{{ $t('새 파일 만들기') }}</router-link></li>
            <li v-if="buttonRole.showCreateButton"><a class="dropdown-item text-white text-center" href="#" @click="createNewFolder">{{ $t('새 폴더 만들기') }}</a></li>
            <li v-if="buttonRole.showUploadButton"><a class="dropdown-item text-white text-center" href="#" @click="btnUploadClick">{{ $t('파일 업로드') }}</a></li>
        </ul>
    </div>
    <form enctype="multipart/form-data">
      <input type="file" ref="uploadDtms" accept=".dtms" @change="uploadDtmsFile" multiple hidden/>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import {$axios, $session} from "dot-framework";
import global from "@/js/global.js";
import Dropdown from "bootstrap/js/dist/dropdown";

export default {
    name: "DropdownMenu",
    inject: ['openCreateModal'],
    mounted() {
        const el = this.$refs["dropdown-menu-button"];
        this.dropdown = new Dropdown(el);
    },
    unmounted() {
        this.dropdown = null;
    },
    props: {
      currentPath: Object,
    },
    data() {
        return {
            dropdown: null,
            isDropdownHidden: true,
            buttonRole: {
                // 새 폴더 버튼 보이기
                showCreateButton: this.buttonRoleCheck,
                showCreateNewCanvasButton: this.buttonRoleCheck,
                showUploadButton: this.buttonRoleCheck,
            },
        }
    },
    computed: {
        ...mapGetters("drive", [
            "driverKind",
            "isPrivate",
            "isPublic",
        ]),
    },
    watch: {
        currentPath() {
            this.buttonRoleCheck();
        },
        driverKind() {
            this.buttonRoleCheck();
        },

    },
    methods: {
        toggleDropdown() {
            if (this.isPrivate) {
                this.dropdown.toggle();
            } else if (this.isPublic) {
                // 닷 관리자일 경우
                if (global.isDotAdmin()) {
                    if (this.currentPath.DTM_GROUP_LEVEL < 2) {
                        this.dropdown.toggle();
                    }
                }
            }
        },
        buttonRoleCheck() {
            this.buttonRole.showCreateButton = false;
            this.buttonRole.showCreateNewCanvasButton = false;
            this.buttonRole.showUploadButton = false;

            if (global.isLogin()) {
                if (this.isPrivate) {
                    this.buttonRole.showCreateButton = true;
                    this.buttonRole.showCreateNewCanvasButton = true;
                    this.buttonRole.showUploadButton = true;
                } else if (this.isPublic) {
                    // 닷 관리자일 경우
                    if (global.isDotAdmin()) {
                        this.buttonRole.showCreateButton = this.currentPath.DTM_GROUP_LEVEL < 2;
                    }
                }
            }
        },
        createNewFolder() {
            this.openCreateModal();
        },
        btnUploadClick() {
            this.$refs.uploadDtms.click();
        },
        async uploadDtmsFile(event) {
          const allowedFileExtensions = /(dtms)$/i; // /(dtms|dtmx|zip|brf|brl)$/i;
          const dtmsFiles = event.target.files;
          const formData = new FormData();
          formData.append('DTM_GROUP_NO', this.$route.query.parentGroupNo || 'ROOT');
          formData.append('DEVICE_KIND', '300');
          formData.append('USER_NO', $session.getUserNo());

          for (const dtmsFile of dtmsFiles) {
            const filename = dtmsFile.name;
            const extension = filename.lastIndexOf('.') !== -1 ? filename.substring(filename.lastIndexOf('.') + 1) : '';

            if (!allowedFileExtensions.test(extension)) {
              return;
            }

            formData.append('DTM_FILES', dtmsFile);
          }

          const url = "/vision-app/v1/dtms/images";
          await $axios.post(url, formData, true)
              .then(response => {
                if (response.status === 201) {
                  // 화면 리프레시
                  this.$store.dispatch('drive/reloadItems', {parentGroupNo: this.$route.query.parentGroupNo});
                }
              })
              .finally(() => {
                event.target.value = "";
              });
        },
    }
}
</script>

<style scoped>
.btn {
    padding: 0;
    width: 48px;
    height: 48px;
    background: #AAABAB;
    border: 1px solid #AAABAB;
}
.btn:hover, .btn:active, .btn:focus {
    background: #ea5414;
    border: 1px solid #ea5414;
}
.dropdown-toggle::after {
    content: none;
}
.dropdown-menu {
    background-color: #AAABAB;
}

.dropdown-menu > li:not(:last-child) {
    border-bottom: 1.5px solid white;
}
.dropdown-menu > li:first-child > a:hover {
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}

.dropdown-menu > li:last-child > a:hover {
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}
</style>
