<template>
    <!-- 키보드 사용자를 위한 건너뛰기 링크 -->
    <ul class="skip-links list-unstyled">
        <li><a href="#main" ref="skipLink" class="skip-link">Skip to main content</a></li>
    </ul>

    <div class="container-fluid">
        <header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between">
            <router-link to="/">
                <img src="/theme/responsive_dot/include/img/dot_logo.svg" width="100" height="25" alt="Dot Incorporation Logo">
            </router-link>
            <!-- 상단 메뉴 영역 ===================================== -->
            <TopMenu></TopMenu>
            <!-- // 상단 메뉴 영역 ================================== -->

            <!-- 로그인 처리 영역 ==================================== -->
            <SignInOut></SignInOut>
            <!-- // 로그인 처리 영역 ================================= -->
        </header>
    </div>
    <div class="p-2 rounded-4">
        <div class="container-fluid overflow-hidden m-0 p-0 mt-1">
            <div class="row" style="margin-left: -8px !important; margin-right: -8px !important;">
                <div class="col-12 col-sm-12 col-md-4 col-xl-3 col-xxl-2 mb-2" style="height: 48px !important;">
                    <input type="radio" class="btn-check" name="drive" id="privateDrive" autocomplete="off" value="P" @change="tabClick" v-bind:checked="driverKind === 'P'">
                    <label class="btn bg-white border-0 rounded-5 text-truncate w-100 h-100" for="privateDrive">{{ `${userName}'s Drive` }}</label>
                </div>

                <div class="col-12 col-sm-12 col-md-4 col-xl-3 col-xxl-2 mb-2" style="height: 48px !important;">
                    <input type="radio" class="btn-check" name="drive" id="publicDrive" autocomplete="off" value="D" @change="tabClick" v-bind:checked="driverKind === 'D'">
                    <label class="btn bg-white border-0 rounded-5 w-100 h-100 text-truncate" for="publicDrive">{{ $t('공용 드라이브') }}</label>
                </div>

                <div class="col-12 col-sm-12 col-md-4 col-xl-3 col-xxl-2 mb-2" style="height: 48px !important;">
                    <form class="w-100 h-100" @keydown.enter.prevent role="search">
                        <input type="search" class="bg-white border-0 rounded-5 w-100 h-100 gap-0"
                                name="search-input" id="search-input"
                                :placeholder="$t('검색')" v-bind:aria-label="$t('검색')" v-model="textFilter">
                    </form>
                </div>
            </div>
        </div>
        <div>
            <navigation-bar :currentPath="currentPath"/>
            <!-- 컨텐츠 표시 영역 ======================================== -->
            <router-view :textFilter="textFilter" :currentPath="currentPath"/>
            <!-- //컨텐츠 표시 영역 ====================================== -->
            <div class="d-flex justify-content-end">
                <dropdown-menu :currentPath="currentPath"/>
            </div>
            <breadcrumb :path="path"/>
        </div>
    </div>


    <!-- 모달 -->
    <create-modal ref="createModal"/>
    <!-- // 모달 -->

    <!-- 하단 표시 영역 ========================================= -->
<!--    <Footer></Footer>-->
    <!-- // 하단 표시 영역 ====================================== -->
</template>

<script>
import {defineAsyncComponent} from 'vue'
import {mapMutations} from "vuex";
import {$axios, $session} from "dot-framework";
import {router} from "@/routes/index.js";

import TopMenu from "./inc/TopMenu";
import SignInOut from "./inc/SignInOut";
import NavigationBar from "@/views/drive/NavigationBar.vue";
import DropdownMenu from "@/views/drive/DropdownMenu.vue";
import Breadcrumb from "@/views/drive/Breadcrumb.vue";
// import Footer from "./inc/Footer";

const CreateModal = defineAsyncComponent(() => import ("@/views/drive/CreateModal.vue"));

export default {
    name: "DriveLayout",
    components: {
        TopMenu,
        SignInOut,
        NavigationBar,
        DropdownMenu,
        Breadcrumb,
        // Footer,
        CreateModal
    },
    data() {
        return {
            currentPath: {},
            path: [],
            textFilter: '',
            userName: $session.getUserInfo().NAME,
        }
    },
    provide() {
        // use function syntax so that we can access `this`
        return {
            openCreateModal: this.openCreateModal
        }
    },
    created() {
        const userInfo = $session.getUserInfo();
        this.userName = userInfo.NAME || $session.getUserNo() || 'GUEST';
    },
    computed: {
        driverKind() {
            return this.$store.getters['drive/driverKind'];
        }
    },
    watch: {
        '$route.query': {
            handler: function () {
                this.fetchPath();
            },
            deep: true,
            immediate: true,
        },
        path(newPath) {
          this.currentPath = Array.isArray(newPath) && newPath.length > 0 ? newPath[newPath.length - 1] : {};
        }
    },
    methods: {
        ...mapMutations("drive", [
            "clearClipboard",
        ]),
        goRoot() {
            router.push({path: "/"});
        },
        tabClick(event) {
            this.$store.commit('drive/setDriveKindStatus', event.target.value);
            router.replace({name: "drive"});
            this.clearClipboard();
        },
        async fetchPath() {
            const params = {
                DTM_GROUP_NO: this.$route.query.parentGroupNo,
                DRIVER_KIND: this.driverKind,
                USER_NO: $session.getUserNo() || ''
            };
            // 값이 없는 속성 제거
            Object.keys(params)
                .map(key => {
                    if (!params[key]) {
                        delete params[key];
                    }
                });
            const query = Object.keys(params).length !== 0 ? `?${new URLSearchParams(params).toString()}` : '';
            const url = `/vision-app/v1/dtms/group/path${query}`;
            const promise = $axios.get(url);
            await promise.then((response) => {
                if (response.status === 200) {
                    this.path = response.data.items;
                }
            });
        },
        openCreateModal() {
            this.$refs.createModal.openNewFolder();
        },
    }
}
</script>

<style scoped>
.container-fluid {
    padding: 10px 2%;
}

/* header {
    padding: 10px 130px 10px 60px;
} */

.rounded-4 {
    border-radius: 30px !important;
    background: #f7f7f7;
    margin: 10px 2%;
    padding: 20px !important;
}
.mb-2 {
    margin: 0 !important;
}
.skip-links {
    position: absolute;
    top: 0;
}

.skip-link {
    right: 100%; /*화면 밖으로 이동*/
    opacity: 0;
    white-space: nowrap;
}

.skip-link:focus {
    right: auto;
    opacity: 1;
}

/* input[type="radio"]:hover + label {
    opacity: 0.8
} */

/* input[type="radio"]:active + label {
    opacity: 0.6
} */
.btn {
    transition: none !important;
}

input[type="radio"]:checked + label {
  background: #EA5414 !important;
  color: white;
}

input[type="radio"]:disabled + label {
  opacity: 0.2
}

input[type="search"] {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 1.5rem;
    padding: 5px 10px;
}

input[type="search"]::placeholder {
    color: #AAABAB;
    text-align: center;
    background: url(../assets/images/search.png) no-repeat 40px center;
}

label {
    background: #eaeaea !important;
    color: #44403F;
    font-size: 1.5rem;
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
