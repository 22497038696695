<template>
  <div class="d-flex justify-content-between bg-white m-1 px-3 rounded-pill align-items-center" style="height: 48px">
    <div class="d-flex gap-3">
      <control-button class="control-prev-icon" @click="goBack()"></control-button>
      <control-button class="control-next-icon" @click="$router.forward()"></control-button>
    </div>
    <span class="col-12 col-lg-auto me-lg-auto ms-3">{{ pathName }}</span>
    <div class="viewIcon">
      <div class="card">
        <input type="radio" class="btn-check" name="options" id="card" value="CARD" @change="setViewMode"
               :checked="viewMode.description === 'CARD'"
               autocomplete="off" aria-label="View in card">
        <label class="btn" for="card"></label>
      </div>
      <div class="list">
        <input type="radio" class="btn-check" name="options" id="list" value="LIST" @change="setViewMode"
               :checked="viewMode.description === 'LIST'"
               autocomplete="off" aria-label="View in grid">
        <label class="btn" for="list"></label>
      </div>
    </div>

  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

const ControlButton = defineAsyncComponent(() => import("@/components/atoms/ControlButton"));

export default {
  name: "NavigationBar",
  components: {
    ControlButton,
  },
  props: {
    currentPath: Object
  },
  data() {
    return {
      pathName: ''
    }
  },
  computed: {
    viewMode() {
      return this.$store.getters['drive/viewMode'];
    }
  },
  watch: {
    currentPath() {
      this.pathName = this.currentPath?.DTM_GROUP_NAME || '';
    }
  },
  methods: {
    setViewMode(event) {
      this.$store.commit('drive/setViewMode', event.target.value);
    },
    goBack() {
      if (window.history.state.back.includes("drive")) {
        this.$router.back();
      }
    }
  }
}
</script>

<style scoped>
.px-3 {
  padding: 0 !important;
  margin-top: 20px !important;
}

span {
  font-size: 1.5rem;
}

.viewIcon:after {
  content: "";
  clear: both;
  display: block;
}

.viewIcon div {
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border: none;
}

.card {
  background: url(../../assets/images/card.png) no-repeat center !important;
}

.list {
  background: url(../../assets/images/list.png) no-repeat center !important;
}

label {
  width: 100%;
  height: 100%;
}

input[type="radio"] + label {
  background: rgba(255, 255, 255, 0.8) !important;
}

input[type="radio"]:checked + label {
  border-color: transparent !important;
  background: rgba(255, 255, 255, 0) !important;
}
</style>
